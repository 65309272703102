/* eslint-disable no-unused-vars */
import isEmpty from 'lodash/isEmpty'

import { apiTerminals } from '@/api/'

function pushMessageLocal(msg, source) {
  const conversations = JSON.parse(localStorage.getItem('terminals')) || []
  let conversationsSource
  let conversationsIndex = null
  if (!isEmpty(conversations)) {
    conversationsSource = conversations.find(item => item.source === source)
    conversationsIndex = conversations.findIndex(item => item.source === source)
  }
  if (!conversationsSource) {
    conversationsSource = {
      source,
      conversations: [],
    }
  }
  conversationsSource.conversations.push({ ...msg })
  if (conversationsIndex !== null) {
    conversations[conversationsIndex] = conversationsSource
  } else {
    conversations.push(conversationsSource)
  }
  localStorage.setItem('terminals', JSON.stringify(conversations))
}

function clearConversationsLocal(source) {
  const conversations = JSON.parse(localStorage.getItem('terminals')) || []
  localStorage.setItem('terminals', JSON.stringify(conversations.filter(item => item.source !== source)))
}

export default {
  namespaced: true,
  state: {
    loading: false,
    activeTerminal: '1G',
    token: null,
    tokenTimeToLive: null,
    intervalId: null,
  },
  getters: {
    getLoading: state => state.loading,
    getToken: state => state.token,
    getActiveTerminal: state => state.activeTerminal,
    getActiveConversations: state => () => {
      const terminalInLocal = localStorage.getItem('terminals')
      return terminalInLocal && JSON.parse(terminalInLocal).length ? JSON.parse(terminalInLocal).find(terminal => terminal.source === state.activeTerminal).conversations : []
    },
  },
  mutations: {
    SET_ACTIVE_TERMINAL(state, val) {
      state.activeTerminal = val
    },
    SET_TOKEN(state, { token, ttl }) {
      state.token = token
      state.tokenTimeToLive = ttl
    },
    CLEAR_TOKEN(state) {
      state.token = null
      state.tokenTimeToLive = null
    },
    SET_INTERVAL_ID(state, val) {
      state.intervalId = val
    },
    SET_LOADING(state, val) {
      state.loading = val
    },
  },
  actions: {
    setActiveTerminal({ commit }, val) {
      commit('SET_ACTIVE_TERMINAL', val)
    },

    // APIs
    createSession({ commit, dispatch }, source) {
      return new Promise((resolve, reject) => {
        commit('SET_LOADING', true)
        apiTerminals.createSession({ source })
          .then(response => {
            commit('SET_TOKEN', { token: response.token, ttl: response.ttl })
            const interval = setInterval(() => {
              dispatch('pingSession')
            }, (response.ttl - 60) * 1000)
            commit('SET_INTERVAL_ID', interval)
            resolve(response)
          })
          .catch(error => reject(error))
          .finally(() => {
            commit('SET_LOADING', false)
          })
      })
    },

    pushMessage(ctx, { msg, source }) {
      pushMessageLocal({ ...msg }, source)
    },

    executeTerminal({ commit, getters }, payload) {
      const { source } = payload
      commit('SET_LOADING', true)
      return new Promise((resolve, reject) => {
        apiTerminals.executeTerminal(payload)
          .then(response => {
            const { data } = response
            pushMessageLocal({ messages: data }, source)
            resolve(response)
          })
          .catch(error => reject(error))
          .finally(() => {
            commit('SET_LOADING', false)
          })
      })
    },

    endSession({ commit, state }) {
      return new Promise((resolve, reject) => {
        apiTerminals.endSession({
          source: state.activeTerminal,
          token: state.token,
        })
          .then(response => {
            commit('CLEAR_TOKEN')
            clearInterval(state.intervalId)
            commit('SET_INTERVAL_ID', null)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    pingSession({ commit, dispatch, state }) {
      return new Promise((resolve, reject) => {
        apiTerminals.pingSession({ source: state.activeTerminal, token: state.token })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    clearConversations({ state }) {
      clearConversationsLocal(state.activeTerminal)
    },
  },
}
