var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "sidebar-left"
  }, [_c('div', {
    staticClass: "sidebar"
  }, [_c('div', {
    staticClass: "sidebar-content card"
  }, [_c('vue-perfect-scrollbar', {
    staticClass: "chat-user-list-wrapper list-group scroll-area",
    attrs: {
      "settings": _vm.perfectScrollbarSettings
    }
  }, [_c('h4', {
    staticClass: "chat-list-title"
  }, [_vm._v(" Danh sách hệ thống đặt vé ")]), _c('ul', {
    staticClass: "chat-users-list contact-list media-list"
  }, _vm._l(_vm.filteredContacts, function (contact) {
    return _c('TerminalsContact', {
      key: contact.value,
      class: {
        'active': _vm.activeChatContactId === contact.value
      },
      attrs: {
        "user": contact,
        "tag": "li"
      },
      on: {
        "click": function click($event) {
          return _vm.$emit('open-chat', contact.value);
        }
      }
    });
  }), 1)])], 1)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }