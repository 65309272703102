<template>
  <!-- Need to add height inherit because Vue 2 don't support multiple root ele -->

  <div
    style="height: inherit"
  >
    <!-- Main Area -->
    <section
      class="chat-app-window"
    >
      <!-- Start Chat Logo -->
      <div
        v-if="!activeChat"
        class="start-chat-area"
      >
        <div class="mb-1 start-chat-icon">
          <feather-icon
            icon="MessageSquareIcon"
            size="56"
          />
        </div>
        <h4 class="sidebar-toggle start-chat-text">
          <!-- @click="startConversation" -->
          Start Conversation
        </h4>
      </div>

      <!-- Chat Content -->
      <div
        v-else
        class="active-chat"
      >
        <!-- Chat Navbar -->
        <div class="chat-navbar">
          <header
            class="chat-header"
          >

            <!-- Avatar & Name -->
            <div class="d-flex align-items-center">

              <!-- Toggle Icon -->
              <div class="sidebar-toggle d-block d-lg-none mr-1">
                <feather-icon
                  icon="MenuIcon"
                  class="cursor-pointer"
                  size="21"
                />
              </div>

              <b-avatar
                size="36"
                :src="avaActiveSource"
                class="mr-1 cursor-pointer badge-minimal"
                variant="light"
                badge
              />
              <h6 class="mb-0">
                {{ activeSource }}
              </h6>
            </div>

            <div class="d-flex align-items-center">
              <BButton
                variant="flat-danger"
                class="text-nowrap d-flex-center px-50"
                @click="clearConversations"
              >
                <feather-icon
                  icon="TrashIcon"
                  size="17"
                  class="cursor-pointer d-sm-block d-none mr-50"
                />
                Xoá lịch sử
              </BButton>
            </div>
          </header>
        </div>

        <!-- User Chat Area -->
        <vue-perfect-scrollbar
          ref="refChatLogPS"
          :settings="perfectScrollbarSettings"
          class="user-chats scroll-area"
        >
          <chat-log
            :chat-data="activeConversations"
            :profile-user-avatar="avaActiveSource"
          />
        </vue-perfect-scrollbar>

        <!-- Message Input -->
        <b-form
          class="chat-app-form"
          @submit.prevent="sendMessage"
        >
          <b-input-group class="input-group-merge form-send-message mr-1">
            <!-- <b-form-textarea
              v-model="chatInputMessage"
              placeholder="Nhập câu lệnh"
              no-resize
              rows="3"
              :formatter="onlyUpperCaseFormatter"
            /> -->
            <b-form-input
              v-model="chatInputMessage"
              placeholder="Nhấn Enter để gửi"
              :formatter="onlyUpperCaseFormatter"
            />
          </b-input-group>
          <b-button
            variant="primary"
            type="submit"
            style="width: 100px;"
            :disabled="loading || !chatInputMessage"
          >
            <b-spinner
              v-if="loading"
              variant="light"
              small
              tag="span"
            />
            <span v-else>Gửi</span>
          </b-button>
        </b-form>
      </div>
    </section>

    <!-- Sidebar -->
    <portal to="content-renderer-sidebar-left">
      <TerminalsLeftSidebar
        :chats-contacts="chatsContacts"
        :contacts="contacts"
        :active-chat-contact-id="activeChat.contact ? activeChat.contact.value : null"
        @open-chat="openChatOfContact"
      />
    </portal>
  </div>
</template>

<script>
import {
  ref, onUnmounted, nextTick, computed, watch,
} from '@vue/composition-api'
import {
  BForm, BInputGroup, BButton, BSpinner, BAvatar, BFormInput,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

import store from '@/store'
import { distributorOptions } from '@/constants/selectOptions'

import { onlyUpperCaseFormatter } from '@core/comp-functions/forms/formatter-input'

import TerminalsLeftSidebar from './TerminalsLeftSidebar.vue'
import terminalsStoreModule from './terminalsStoreModule'

export default {
  components: {
    BSpinner,
    BForm,
    BInputGroup,
    BFormInput,
    BButton,
    BAvatar,
    VuePerfectScrollbar,
    TerminalsLeftSidebar,
    ChatLog: () => import('./ChatLog.vue'),
  },
  setup() {
    const TERMINALS_APP_STORE_MODULE_NAME = 'app-terminals'

    // Register module
    if (!store.hasModule(TERMINALS_APP_STORE_MODULE_NAME)) store.registerModule(TERMINALS_APP_STORE_MODULE_NAME, terminalsStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      store.dispatch(`${TERMINALS_APP_STORE_MODULE_NAME}/endSession`)
      if (store.hasModule(TERMINALS_APP_STORE_MODULE_NAME)) store.unregisterModule(TERMINALS_APP_STORE_MODULE_NAME)
    })

    // Scroll to Bottom ChatLog
    const refChatLogPS = ref(null)
    const scrollToBottomInChatLog = () => {
      const scrollEl = refChatLogPS.value.$el || refChatLogPS.value
      scrollEl.scrollTop = scrollEl.scrollHeight
    }
    const loading = computed(() => store.getters[`${TERMINALS_APP_STORE_MODULE_NAME}/getLoading`])

    // ------------------------------------------------
    // Chats & Contacts
    // ------------------------------------------------
    const chatsContacts = ref([])
    const contacts = ref(distributorOptions.filter(item => item.value === '1G'))

    const activeChat = computed(() => store.getters[`${TERMINALS_APP_STORE_MODULE_NAME}/getActiveTerminal`])
    const activeToken = computed(() => store.getters[`${TERMINALS_APP_STORE_MODULE_NAME}/getToken`])
    watch(activeChat, () => {
      store.dispatch(`${TERMINALS_APP_STORE_MODULE_NAME}/createSession`, activeChat.value)
    }, { immediate: true })

    const chatInputMessage = ref('')

    // const activeConversations = computed(() => store.getters[`${TERMINALS_APP_STORE_MODULE_NAME}/getActiveConversations`])
    const activeConversations = ref([])
    const fetchConversations = () => {
      activeConversations.value = store.getters[`${TERMINALS_APP_STORE_MODULE_NAME}/getActiveConversations`]()
      nextTick(() => { scrollToBottomInChatLog() })
    }
    fetchConversations()
    const openChatOfContact = source => {
      chatInputMessage.value = ''

      store.dispatch('app-terminals/setActiveTerminal', source)
        .then(() => {
          nextTick(() => { scrollToBottomInChatLog() })
        })
    }
    const sendMessage = async () => {
      if (!chatInputMessage.value || loading.value) return

      const source = activeChat.value
      const commands = chatInputMessage.value.trim().split('\n')
      const token = activeToken.value

      await store.dispatch('app-terminals/pushMessage', { msg: { messages: commands, token }, source })
      fetchConversations()
      const payload = { source, commands, token }
      store.dispatch('app-terminals/executeTerminal', payload)
        .then(() => {
          fetchConversations()
          // Reset send message input value
          chatInputMessage.value = ''
        })
    }

    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    }

    // Active Chat Contact Details
    const shallShowActiveChatContactSidebar = ref(false)
    async function clearConversations() {
      await store.dispatch('app-terminals/clearConversations')
      fetchConversations()
    }

    const avaActiveSource = computed(() => distributorOptions.find(item => item.value === activeChat.value)?.image || '')
    const activeSource = computed(() => distributorOptions.find(item => item.value === activeChat.value)?.label || '')
    return {
      avaActiveSource,
      activeSource,
      // Chat & Contacts
      chatsContacts,
      contacts,

      // Single Chat
      refChatLogPS,
      activeChat,
      chatInputMessage,
      openChatOfContact,
      sendMessage,

      // Active Chat Contact Details
      shallShowActiveChatContactSidebar,

      // UI
      perfectScrollbarSettings,

      activeConversations,
      clearConversations,
      onlyUpperCaseFormatter,
      loading,
    }
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-chat.scss";
@import "~@core/scss/base/pages/app-chat-list.scss";
</style>
