var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticStyle: {
      "height": "inherit"
    }
  }, [_c('section', {
    staticClass: "chat-app-window"
  }, [!_vm.activeChat ? _c('div', {
    staticClass: "start-chat-area"
  }, [_c('div', {
    staticClass: "mb-1 start-chat-icon"
  }, [_c('feather-icon', {
    attrs: {
      "icon": "MessageSquareIcon",
      "size": "56"
    }
  })], 1), _c('h4', {
    staticClass: "sidebar-toggle start-chat-text"
  }, [_vm._v(" Start Conversation ")])]) : _c('div', {
    staticClass: "active-chat"
  }, [_c('div', {
    staticClass: "chat-navbar"
  }, [_c('header', {
    staticClass: "chat-header"
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "sidebar-toggle d-block d-lg-none mr-1"
  }, [_c('feather-icon', {
    staticClass: "cursor-pointer",
    attrs: {
      "icon": "MenuIcon",
      "size": "21"
    }
  })], 1), _c('b-avatar', {
    staticClass: "mr-1 cursor-pointer badge-minimal",
    attrs: {
      "size": "36",
      "src": _vm.avaActiveSource,
      "variant": "light",
      "badge": ""
    }
  }), _c('h6', {
    staticClass: "mb-0"
  }, [_vm._v(" " + _vm._s(_vm.activeSource) + " ")])], 1), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('BButton', {
    staticClass: "text-nowrap d-flex-center px-50",
    attrs: {
      "variant": "flat-danger"
    },
    on: {
      "click": _vm.clearConversations
    }
  }, [_c('feather-icon', {
    staticClass: "cursor-pointer d-sm-block d-none mr-50",
    attrs: {
      "icon": "TrashIcon",
      "size": "17"
    }
  }), _vm._v(" Xoá lịch sử ")], 1)], 1)])]), _c('vue-perfect-scrollbar', {
    ref: "refChatLogPS",
    staticClass: "user-chats scroll-area",
    attrs: {
      "settings": _vm.perfectScrollbarSettings
    }
  }, [_c('chat-log', {
    attrs: {
      "chat-data": _vm.activeConversations,
      "profile-user-avatar": _vm.avaActiveSource
    }
  })], 1), _c('b-form', {
    staticClass: "chat-app-form",
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.sendMessage($event);
      }
    }
  }, [_c('b-input-group', {
    staticClass: "input-group-merge form-send-message mr-1"
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": "Nhấn Enter để gửi",
      "formatter": _vm.onlyUpperCaseFormatter
    },
    model: {
      value: _vm.chatInputMessage,
      callback: function callback($$v) {
        _vm.chatInputMessage = $$v;
      },
      expression: "chatInputMessage"
    }
  })], 1), _c('b-button', {
    staticStyle: {
      "width": "100px"
    },
    attrs: {
      "variant": "primary",
      "type": "submit",
      "disabled": _vm.loading || !_vm.chatInputMessage
    }
  }, [_vm.loading ? _c('b-spinner', {
    attrs: {
      "variant": "light",
      "small": "",
      "tag": "span"
    }
  }) : _c('span', [_vm._v("Gửi")])], 1)], 1)], 1)]), _c('portal', {
    attrs: {
      "to": "content-renderer-sidebar-left"
    }
  }, [_c('TerminalsLeftSidebar', {
    attrs: {
      "chats-contacts": _vm.chatsContacts,
      "contacts": _vm.contacts,
      "active-chat-contact-id": _vm.activeChat.contact ? _vm.activeChat.contact.value : null
    },
    on: {
      "open-chat": _vm.openChatOfContact
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }