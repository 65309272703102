<template>
  <div class="sidebar-left">
    <div class="sidebar">
      <!-- Sidebar Content -->
      <div
        class="sidebar-content card"
      >
        <!-- ScrollArea: Chat & Contacts -->
        <vue-perfect-scrollbar
          :settings="perfectScrollbarSettings"
          class="chat-user-list-wrapper list-group scroll-area"
        >
          <!-- Contacts Title -->
          <h4 class="chat-list-title">
            Danh sách hệ thống đặt vé
          </h4>

          <!-- Contacts -->
          <ul class="chat-users-list contact-list media-list">
            <TerminalsContact
              v-for="contact in filteredContacts"
              :key="contact.value"
              :user="contact"
              tag="li"
              :class="{'active': activeChatContactId === contact.value}"
              @click="$emit('open-chat', contact.value)"
            />
          </ul>
        </vue-perfect-scrollbar>
      </div>

    </div>
  </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { computed } from '@vue/composition-api'

export default {
  components: {
    VuePerfectScrollbar,
    TerminalsContact: () => import('./TerminalsContact.vue'),
  },
  props: {
    contacts: {
      type: Array,
      required: true,
    },
    activeChatContactId: {
      type: [Number, String],
      default: null,
    },
  },
  setup(props) {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    }
    const filteredContacts = computed(() => props.contacts)

    return {
      filteredContacts,
      perfectScrollbarSettings,
    }
  },
}
</script>
